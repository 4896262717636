import * as types from '../mutation-types';
import { firstLevel } from '@/configs/global.config';

export default {
	namespaced: true,
	state: {
        entities: [],
        entitiesBrief: [],
        entitiesFiltered:[],
        allEntities: [],
        entitiesAll: []
	},

	actions: {
        fetchByFilter({ commit }, { type, name, workloadIds, specialtyId, regionIds, page, perPage, nonDeleted, OrderByWorkLoad, OrderByName, WithVisitsCount, includeAddresses, includeContactInfo, employeeId }){
            var urlSearchParams = new URLSearchParams();
            var params = { type, name, specialtyId, page, perPage, nonDeleted, OrderByWorkLoad, OrderByName, WithVisitsCount, employeeId }

            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) urlSearchParams.append(param, params[param])
            });
            regionIds.forEach(id => {
                urlSearchParams.append('regionIds', id)
            })
            workloadIds.forEach(id => {
                urlSearchParams.append('workloadIds', id)
            })
            urlSearchParams.append('includeContactInfo', true);
            urlSearchParams.append('includeAddresses', true);
            
            return window.axios.get('/entities', {
                params: urlSearchParams
            })
            .then((response) => {
                commit(types.STORE_ENTITIES, response.data);
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                };
            })
        },

        fetchAllBrief({ commit }, { type, regionIds, preventStoreEntities, filterByTeamSpec }) {
            var urlSearchParams = new URLSearchParams();
            var params = { type, filterByTeamSpec }
            regionIds.forEach(regionId => { urlSearchParams.append('regionIds', regionId) })
            // urlSearchParams.append('filterByTeamSpec', true);
            firstLevel ? urlSearchParams.append('filterByTeamSpec', false) : urlSearchParams.append('filterByTeamSpec', true);
            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) urlSearchParams.append(param, params[param])
            });
         
            return window.axios.get('/entities/all/brief', { params: urlSearchParams }).then((response) => {
                if (!preventStoreEntities) {
                    commit(types.STORE_ENTITIES_BRIEF, response.data);
                }
                return response.data
            })
        },
        
        // visited doctor
        fetchVisitedDocotors({ commit }){
            return window.axios.get('/entities', {
                params:{
                    type: 1,
                    WithVisitsCount: true,
                    perPage: 50,
                    nonDeleted: true,
                }
            }).then(response => {
                return response.data;
            })
        },

        fetchVisitedDoctorsByFilter({ commit }, { name, workloadIds, specialtyId, regionIds, withVisitsCountFrom, WithVisitsCountTo, page, perPage, IncludeAddresses, bestTimeToVisit, openingTime, closingTime, residentDoctorNames, EmployeeId }){
            var urlSearchParams = new URLSearchParams();
            var params = { name, specialtyId, withVisitsCountFrom, WithVisitsCountTo, page, perPage, IncludeAddresses, bestTimeToVisit, openingTime, closingTime, residentDoctorNames, EmployeeId }

            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) urlSearchParams.append(param, params[param])
            });
            regionIds.forEach(id => {
                urlSearchParams.append('regionIds', id)
            })
            workloadIds.forEach(id => {
                urlSearchParams.append('WorkloadIds', id)
            })
            urlSearchParams.append('type', 1);
            urlSearchParams.append('nonDeleted', true);
            urlSearchParams.append('WithVisitsCount', true);
            
            return window.axios.get('/entities', {
                params: urlSearchParams
            })
            .then((response) => {
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                }
            })
        },

        create({ commit }, { data }){
            return window.axios.post('/entities', data);
        },
        
        getById({ commit }, { id }) {
            return window.axios.get(`/entities/${id}`).then(({ data }) => {
                return data
            })
        },
        update({ commit }, { id, data }){
            return window.axios.put(`/entities/${id}`, data);
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/entities/${id}`);
        },

        clearEntitiesBrief({ commit }){
            commit(types.CLEAR_ENTITIES);
        },
        // get all entities for just customer page 
        fetchAllEntities ({ commit }, { type, name, workloadIds, specialtyId, regionIds, page, perPage, nonDeleted, OrderByWorkLoad, OrderByName, WithVisitsCount, filterByTeamSpec }) {
            var urlSearchParams = new URLSearchParams();
            var params = { type, name, specialtyId, page, perPage, nonDeleted, OrderByWorkLoad, OrderByName, WithVisitsCount, filterByTeamSpec }

            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) urlSearchParams.append(param, params[param])
            });
            regionIds.forEach(id => {
                urlSearchParams.append('regionIds', id)
            })
            workloadIds.forEach(id => {
                urlSearchParams.append('workloadIds', id)
            })
            urlSearchParams.append('includeContactInfo', true);
            urlSearchParams.append('includeAddresses', true);
        
            firstLevel ? urlSearchParams.append('filterByTeamSpec', false) : urlSearchParams.append('filterByTeamSpec', true);

            return window.axios.get('/AllEntities', {
                params: urlSearchParams
            })
            .then((response) => {
                commit(types.STORE_ALL_ENTITIES, response.data);
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                };
            })
        },

        merge({ commit }, { oldEntityId, newEntityId }) {
            return window.axios.put(`merge/oldEntity/${oldEntityId}/newEntity/${newEntityId}`)
        },

        updateActive({ commit }, { id, active }) {
            return window.axios.put(`ActiveEntity/${id}`, {}, { params: { active } })
        }
	},

	mutations: {
		[types.STORE_ENTITIES](state, entities){
            state.entities = entities;
        },
		[types.STORE_ENTITIES_BRIEF](state, entitiesBrief){
            state.entitiesBrief = entitiesBrief;
        },
        [types.STORE_ENTITIES_ALL](state, allEntities) {
            state.allEntities = allEntities
        },
        [types.CLEAR_ENTITIES](state, entitiesBrief) {
            state.entitiesBrief = []
        },
        [types.STORE_ALL_ENTITIES](state, entitiesAll){
            state.entitiesAll = entitiesAll
        }
	},

	getters:{
		getEntityById: state => id => state.entities.find(c => c.id === id),
        getEntityByName: state => name => state.entities.find(c => c.id === name),
        getCustomerById: state => id => state.allEntities.find(c => c.id === id)
	}
}