import i18n from '@/i18n';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import ar from 'vuetify/lib/locale/ar';
import en from 'vuetify/lib/locale/en';

import colors from 'vuetify/lib/util/colors'
import { colors as brandColors } from '@/configs/brand.config';
Vue.use(Vuetify);

export default new Vuetify({
	ltr: i18n.locale === 'en',
	lang: {
		locales: { en, ar },
		current: i18n.locale,
	},
	breakpoint: {
		thresholds: {
		  lg: 1900,
		},
		scrollBarWidth: 24,
	},

	theme: {
		themes: {
			light: {
				// primary: brandColors.primary,
				// 'primary-light': brandColors.primaryLight,
				// secondary: brandColors.secondary,
				// 'light-blue': brandColors.lightBlue,
				success: '#13a689',
				error: '#ff3334',
				errorLight: '#eeb4b3',
				green: '#0f8145',
				'light-success': '#13a689',
				'baby-blue': "#d6e9ee",
				accent: colors.indigo.base, // #3F51B5,
				'alert-color': '#e94747',
				'edit-color': '#dd8b1b',
				'orange-light': '#fba819',

				primary: "#253c99",
				secondary: "#f57f20",
				'primary-light': '#b2c1ff',
				'light-blue': '#409dd6'
			},
		},
	},
});
